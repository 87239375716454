<!-- A设备管理-备件管理-备件入库记录-详情 -->
 <!-- A设备管理-备件管理-备件入库记录--添加入库记录 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">入库信息</div>
            <el-form :inline="true" :model="formInline" :label-width="formLabelWidth" class="mg_top_30 flex flex_wrap"
                style="width: 65%;">
                <el-form-item label="是否领用：">
                    <el-radio-group v-model="formInline.isPurchase" class="width_180" disabled>
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="formInline.isPurchase" label="领用单号：" class=" ">
                    <el-input v-model="formInline.pickOrderID" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item v-if="!formInline.isPurchase" label="是否审批：">
                    <el-select v-model="formInline.isAuth" placeholder="" class="width_180" disabled>
                        <el-option v-for="item in isAuthArray" :key="item.value" :label="item.name" :value="item.value" >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item v-if="!formInline.isPurchase" label="出库用途：" class=" " >
                    <el-input v-model="formInline.outwhUse" placeholder="" class="width_180" disabled></el-input>
                </el-form-item> -->
                <el-form-item v-if="!formInline.isPurchase" label="仓库：" class=" ">
                    <el-input v-model="formInline.warehouse" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item v-if="!formInline.isPurchase" label="部门：" class=" ">
                    <el-input v-model="formInline.department" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
                <el-form-item v-if="!formInline.isPurchase" label="出库总价：" class=" ">
                    <el-input v-model="formInline.totalPrice" placeholder="" class="width_180" type="number" disabled></el-input>
                </el-form-item>
                <el-form-item v-if="!formInline.isPurchase" label="申请人：" class=" ">
                    <el-input v-model="formInline.applyPerson" placeholder="" class="width_180" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-form v-if="!formInline.isPurchase" :model="formInline" :label-width="formLabelWidth" style="width:65%; ">
                  <el-form-item v-if="!formInline.isPurchase" label="出库用途：" class=" " >
                    <el-input v-model="formInline.outwhUse"  type="textarea" :rows="3" resize="none" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="user">
                    <el-input type="textarea" :rows="3" v-model="formInline.remarks" resize="none" disabled></el-input>
                </el-form-item>
            </el-form>

            <div v-if="!formInline.isPurchase" class="box_head1 width_100">备件信息</div>
            <!-- 按钮 -->
            <!-- <div class="flex mg_top_10" style="width: 65%;">
                <el-button v-if="!formInline.isPurchase" type="primary" icon="el-icon-circle-plus-outline"
                    @click="selectSparePart">选择备件</el-button>
            </div> -->
            <!-- table -->
            <div v-if="!formInline.isPurchase" class="height_300" style="width: 65%;">
                <el-table :data="tableData" tooltip-effect="dark" class="mg_top_15 " height="100%" border
                    style="width: 100%;">
                    <el-table-column type="index" label="序号" width="70">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unitPrice" label="单价">
                    </el-table-column>
                    <!-- <el-table-column prop="insuredQuan" label="参考价（元）" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.insuredQuan"></el-input>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="applyQuan" label="申请数量" show-overflow-tooltip>
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.realQuan" type="number"></el-input>
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="totalPrice" label="总价" show-overflow-tooltip>
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.profitQuan" type="number"></el-input>
                        </template> -->
                    </el-table-column>
                    <!-- <el-table-column prop="lossQuan" label="lossQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.lossQuan"></el-input>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.remarks"></el-input>
                        </template> -->
                    </el-table-column>
                    <!-- <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delStandard(scope.$index)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
            <div class="flex mg_top_30 mg_bottom_30">
                <!-- <el-button type="primary" class="width_140" @click="sbumit">确定</el-button> -->
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>

            <el-dialog :visible.sync="sparePartDialog" top='15vh'>
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">选择备件台账</div>
                <div class="mg_top_10  fn_size14 color_1A1A1A height_500">
                    <el-table ref="multipleTable" :data="sparePartList" style="width: 100%;" height="100%"
                        class="mg_top_10" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column type="index" label="序号" width="70">
                        </el-table-column>
                        <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                <div class="flex justify_center pdb_20 mg_top_20">
                    <el-button @click="sparePartDialog = false">取 消</el-button>
                    <el-button type="primary" @click="sureSparePart">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>

</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        // sparePartStockQueryLimit,
        // sparePartInWarehouseInsert,
        sparePartInWarehouseQuery,
        sparePartInWarehouseDetailQuery
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                formInline: {
                    // isPurchase: false
                },
                formLabelWidth: '110px',
                tableData: [],
                isAuthArray: [{
                    name: '是',
                    value: true
                }, {
                    name: '否',
                    value: false
                }],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                userID: '',
                sparePartDialog: false,
                sparePartList: [],
                detailID:''
            }
        },
        mounted() {
              if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.sparePartInWarehouseQuery();
                // this.sparePartOutWarehouseDetailQuery();
            }
            // if (localStorage.getItem('userInfo') != null) {
            //     let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            //     this.userID = userInfo.userID
            // }
        },
        methods: {
            async sparePartInWarehouseQuery() {
                var param = {
                    ID:this.detailID
                }
                const res = await sparePartInWarehouseQuery(param);
                this.formInline = res.rows[0];
                this.sparePartInWarehouseDetailQuery()
            },
            async sparePartInWarehouseDetailQuery() {
                 var param = {
                    orderID:this.formInline.orderID
                }
                const res = await sparePartInWarehouseDetailQuery(param);
                this.tableData = res.rows
            },







            sbumit() {
                console.log(this.formInline.isPick)
                console.log(this.formInline)
                console.log(this.tableData)
                 this.formInline.userID = this.userID
                if (this.formInline.isPick) {   
                    // this.formInline.userID = this.userID
                } else {
                    this.formInline.orderDetailJSON = this.tableData
                }
                this.sparePartInWarehouseInsert();
            },
            // 备件入库新增
            async sparePartInWarehouseInsert() {
                console.log(this.formInline)
                const res = await sparePartInWarehouseInsert(this.formInline);
                if (res && res.data && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                } else {
                    this.$message.error(res.message);
                }
            },
            // 
            colsePage() {
                this.$router.go(-1);
            },
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                const res = await sparePartStockQueryLimit(param);
                this.sparePartList = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(this.multipleSelection)
            },
            sureSparePart() {
                // console.log(this.sparePartList);
                // var testData = this.tableData.filter(itmer => itmer.sparePartID == this.currentRow.sparePartID);
                // // console.log(testData)
                // if (testData.length != 0) {
                //     this.$message({
                //         message: '警告，此设备已添加',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.tableData = [];
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    var testData = {
                        sparePartID: '', // 备件编号
                        sparePartName: '', // 备件名称
                        sparePartModel: '', // 备件型号
                        unit: '', // 单位
                        unitPrice: 0, // 参考价格（元）
                        applyQuan: 1, // 申请数量
                        totalPrice: 0, // 总价格
                        remarks: '暂无' // 备注
                    }
                    testData.sparePartID = this.multipleSelection[i].sparePartID;
                    testData.sparePartName = this.multipleSelection[i].sparePartName;
                    testData.sparePartModel = this.multipleSelection[i].sparePartModel;
                    testData.unit = this.multipleSelection[i].unit;
                    testData.unitPrice = this.multipleSelection[i].referPrice;
                    testData.totalPrice = this.multipleSelection[i].referPrice;
                    this.tableData.push(testData)
                }
                this.sparePartDialog = false;
                this.multipleSelection = [];
                // sparePartID
            },
            handleCurrentChange(val) {
                this.sparePartStockQueryLimit(); //查询table数据的方法
            },
            selectSparePart() {
                // this.$nextTick(() => {
                //     if (this.$refs['multipleTable']) {
                //         this.$refs['multipleTable'].clearSelection();
                //     }
                // });
                this.sparePartStockQueryLimit()
                this.sparePartDialog = true;
            },
            // table合计
            // getSummaries(param) {
            //     const {
            //         columns,
            //         data
            //     } = param;
            //     const sums = [];
            //     columns.forEach((column, index) => {
            //         if (index === 2) {
            //             sums[index] = '总价';
            //             return;
            //         }
            //         const values = data.map(item => Number(item[column.property]));
            //         //console.log(values);//values是各组数据
            //         if (!values.every(value => isNaN(value))) {
            //             sums[index] = values.reduce((prev, curr) => {
            //                 const value = Number(curr);
            //                 console.log(sums[index]); //values是各组数据
            //                 if (!isNaN(value)) {
            //                     return prev + curr;
            //                 } else {
            //                     return prev;
            //                 }
            //             }, 0);
            //             sums[index] += ' ';
            //         } else {
            //             sums[index] = '';
            //         }
            //     });
            //     return sums;
            // }
        }

    }
</script>
<style scoped>
</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        width: 36px;
        padding: 4px;
        background-color: #FFFFFF;
        border: none;
    }
</style>